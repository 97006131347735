
@import url('https://fonts.googleapis.com/css?family=Poppins');

/* html {
    font-size: 0.8rem;
} */

@media only screen and (min-width: 471px) {
  html {
    font-size: 0.8rem;
}
  }
  
  @media only screen and (max-width: 470px) {
    html {
      font-size: 0.5rem;
  }
  }

body {
    margin: 0px;
    height: 100%;
    /* padding: 2rem; */
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #F1F1F1;
    font-family: 'Poppins', cursive;
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fixedSidebar {
    position: fixed;
    left: 0;
    top: 0;
    /* width: 15%; 
    height: 100vh; */
    /* background-color: #f0f0f0; Adjust background color as needed */
    /* border-right: 1px solid #ccc; /* Add a border if you want */
    z-index: 10
    /* Add any other styles you need */
  }



  :root {
    font-family: 'Poppins', cursive;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-variation-settings: normal;
    --font-family: 'Poppins', cursive;
    --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    --surface-a: #ffffff;
    --surface-b: #f9fafb;
    --surface-c: #f3f4f6;
    --surface-d: #e5e7eb;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #4b5563;
    --text-color-secondary: #6b7280;
    --primary-color: #4318FF;
    --primary-color-text: #ffffff;
    --surface-0: #ffffff;
    --surface-50: #f9fafb;
    --surface-100: #f3f4f6;
    --surface-200: #e5e7eb;
    --surface-300: #d1d5db;
    --surface-400: #9ca3af;
    --surface-500: #6b7280;
    --surface-600: #4b5563;
    --surface-700: #374151;
    --surface-800: #1f2937;
    --surface-900: #111827;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: #f9fafb;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dfe7ef;
    --surface-hover: #f6f9fc;
    --focus-ring: 0 0 0 0.2rem #a5f3fc;
    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: #ecfeff;
    --highlight-text-color: #0e7490;
    color-scheme: light;
  }
  
  @font-face {
    font-family: 'Poppins', cursive;
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: "Regular";
    /* src: url("/primereact/resources/themes/lara-light-cyan/fonts/Inter-roman.var.woff2?v=3.19") format("woff2"); */
  }
  @font-face {
    font-family:'Poppins', cursive;
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: "Italic";
    /* src: url("./fonts/Inter-italic.var.woff2?v=3.19") format("woff2"); */
  }


  .CircularProgressbar-background {
    fill: green;
  }

  .hero {
    background-color: '#EBEBEB'
  }

  #error {
    color:red
  }
  
  .templateCard:hover {
    border: 1px solid #4318FF;
  }

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    /* border: 1px solid transparent; */
    /* border-bottom:  2px solid #4318FF; */
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    /* background: #fff; */
    /* border: 1px solid transparent; */
    border-bottom:  2px solid #4318FF;
    /* border-color: #4318FF; */
    color: black;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    /* background: #fff; */
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  